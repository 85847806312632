import React, { useState, useEffect } from 'react';
import './History.css';  // Ensure the path to your CSS file is correct

function History() {
  const [selectedYear, setSelectedYear] = useState('1980');
  const timelineContent = {
    '1980': {
      year: '1980',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
            <h3>1980's - 1990's</h3>
            <p>

<h1>Single cut diamond</h1>

            

Exploring the Charm of Single Cut Diamonds from the 1980s and 1990s

In the diamond industry, the single cut represents a classic and straightforward method of shaping round diamonds. Characterized by its minimalistic approach, the single cut typically features only 17 or 18 facets. This simplicity stands in stark contrast to the more common full cut diamond, which boasts 58 facets.

Single cut diamonds, generally found in smaller carat weights of 1.2mm or less, offer a unique aesthetic appeal that has remained highly valued, particularly throughout the 1980s and 1990s. Due to their fewer yet larger facets, these diamonds produce a striking display of light and color. This “majestic dance” of brilliance is something that often gets subdued in full cut diamonds, where the numerous facets create a uniform white light.</p>
          </div>
          <div className="image">
            <img src="/TK1.jpg" alt="2000" />
          </div>
        </div>
      )
    },
  
    '2000': {
      year: '2000',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
         
            <p>

<h1>


Brilliant Cut Diamonds</h1>

            

The brilliant cut stands as a masterpiece in the art of diamond cutting, distinguished by its remarkable ability to amplify light and sparkle with an unmatched intensity. This cut is not merely a style but a testament to precision engineering designed to enhance the inherent beauty of a diamond or other gemstones.

Unmatched Radiance
Developed to maximize the light that travels through the diamond, the brilliant cut is characterized by its unique geometry. Its facets are strategically arranged in a cone-like shape, allowing for optimal light reflection. This meticulous arrangement ensures that light enters the gemstone and is reflected from one facet to another before dazzling the viewer's eye with an extraordinary display of brilliance and fire.

.</p>
          </div>
          <div className="image">
            <img src="/2122.jpg" alt="2000" />
          </div>
        </div>
      )
    },
    '2008': {
      year: '2008',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
           
            <p>

<h1>Rose Cut Diamonds: Timeless Elegance Meets Modern Mastery</h1>

            

At Royal Rays, we introduced the timeless beauty of rose cut diamonds to our collections in 2008, revitalizing this ancient art with our modern craftsmanship. As a diamond cutting style that originated in 16th-century India, the rose cut was initially designed to emulate the unfolding petals of a rose. This historical approach created a unique, dome-shaped diamond that features 24 triangular facets, culminating at a single apex to capture and reflect light in a soft, mesmerizing glow.</p>
          </div>
          <div className="image">
            <img src="/rosect.jpg" alt="2000" />
          </div>
        </div>
      )
    },
    '2012': {
      year: '2012',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
         
            <p>

<h1>Royal Rays' Fancy Colored Diamonds</h1>

            


In 2012, Royal Rays proudly expanded its collection to include natural, intensely vibrant fancy colored diamonds, enriching our offerings with some of the world’s most captivating gemstones. Renowned for their rarity and extraordinary hues, these diamonds are sourced to provide a broad palette of color options to our discerning clientele.Since introducing fancy colored diamonds, Royal Rays has been at the forefront of delivering luxury that transcends generations. Our commitment to excellence and passion for unique gemstones have made us a trusted name among those who cherish the rarity and beauty of natural colored diamonds.</p>
          </div>
          <div className="image">
            <img src="/121.jpg" alt="2000" />
          </div>
        </div>
      )
    },
    '2015': {
      year: '2015',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
        
            <p>

<h1>
Royal Rays' Step Cut Diamonds: The Art of Precision Since 2015</h1>

            


In 2015, Royal Rays introduced an exquisite selection of step cut diamonds, adding a new dimension of elegance and precision to our esteemed collection. Step cut diamonds, known for their clean lines and geometric symmetry, offer a distinct clarity and an understated brilliance that appeals to connoisseurs of classic and contemporary jewelry alike.Since their introduction, Royal Rays' step cut diamonds have become a cornerstone of our offerings, favored by customers seeking timeless elegance. Whether set in a solitaire engagement ring or as part of a more elaborate setting, these diamonds make a statement of refined taste and exceptional craftsmanship.</p>
          </div>
          <div className="image">
            <img src="/00.jpg" alt="2000" />
          </div>
        </div>
      )
    },
    '2019': {
      year: '2019',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
           
            <p>

<h1>
EUROPEAN CUT / MINE CUT:</h1>

            


In 2019, Royal Rays unveiled its magnificent array of EUROPEAN CUT, a collection that embodies the precise and elegant facets of traditional craftsmanship. Celebrated for their architectural beauty, EUROPEAN CUT diamonds distinguish themselves through their rectangular facets, which are arranged parallel to the diamond's edges and emphasize its depth and clarity.</p>
          </div>
          <div className="image">
            <img src="/213.jpg" alt="2000" />
          </div>
        </div>
      )
    },
    '2024': {
      year: '2024',
      content: (
        
        <div className="timeline-item-content">
          
          <div className="content">
    
            <p>

<h1>Royal Rays' 2024 Alphabet Diamonds</h1>

            

In 2024, Royal Rays is proud to unveil a groundbreaking collection: the Alphabet Diamonds. This bespoke series celebrates individuality and innovation, featuring exquisitely crafted diamonds cut into every letter of the alphabet.As with all Royal Rays creations, these alphabet-shaped diamonds are sourced and crafted with an unwavering commitment to ethical practices and exceptional quality. Each piece is a testament to our dedication to innovation, blending the timeless allure of diamonds with a modern twist that speaks to the current trends and personal expressions of our clientele.</p>
          </div>
          <div className="image">
            <img src="/9.png" alt="2000" />
          </div>
        </div>
      )
    },
  };
  const years = Object.keys(timelineContent);

  useEffect(() => {
    const currentIndex = years.indexOf(selectedYear);
    const nextIndex = (currentIndex + 1) % years.length;
    const timer = setTimeout(() => {
      setSelectedYear(years[nextIndex]);
    }, 3000); // Change year every 3 seconds

    return () => clearTimeout(timer); // Clear timeout on cleanup
  }, [selectedYear, years]);

  return (
    <div className="history-container">
      <div className="top-image-container">
        <img src="/HDTHREE.jpeg" alt="Royal Rays Legacy" className="top-image" />
      </div>

      <section className="logo-section">
        <img src="/BRR.png" alt="Royal Rays Logo" className="logo" style={{ height: '150px', marginTop: '40px' }} />
        <div className="logo-content">
          <p>Founded in 1986, Royal Rays BV has been a pioneer in the diamond industry, delivering unparalleled craftsmanship and quality for over three decades.

We pride ourselves on our meticulous craftsmanship, ensuring each diamond is cut to perfection to maximize brilliance and beauty.

As manufacturers, we source our diamonds directly, guaranteeing authenticity and providing cost efficiencies that we pass on to our valued customers.

Whether it's for a special occasion or a timeless piece of everyday luxury, our diamonds are available in a variety of cuts and settings to suit every style and preference.

At Royal Rays BV, customer satisfaction is our top priority. Our dedicated team ensures a seamless experience from selection to purchase, with personalized service at every step.

We uphold the highest ethical standards in diamond sourcing and production, ensuring each diamond is conflict-free and ethically mined.
          </p>
         
        </div>
      </section>

      <section className="journey-section">
        <h1 className="journey-title">OUR JOURNEY</h1>
        <h2 className="journey-tagline">From Tradition to Innovation – Our Diamond Journey Since 1985</h2>
        <div className="timeline-container">
          <div className="timeline">
            {years.map((year) => (
              <div
                key={year}
                className={`timeline-item ${selectedYear === year ? 'selected' : ''}`}
                onClick={() => setSelectedYear(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
        <div className="timeline-content">
          {timelineContent[selectedYear].content}
        </div>
      </section>
    </div>
  );
}

export default History;
