import React from 'react';

const Contactus = () => {
  return (
    <div style={{ backgroundColor: 'white', padding: '20px', color: 'black' }}> {/* Changed to white background and black text */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
    
        <div style={{ width: '45%' }}>
          <h2 style={{ borderBottom: '1px solid #333', paddingBottom: '10px', color: 'black' }}>CONTACT FORM</h2>
          <form action="https://formspree.io/f/xjvqqgbw" method="POST">
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="name" style={{ color: 'black' }}>Name *</label>
              <input type="text" id="name" name="name" placeholder="Full Name" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="email" style={{ color: 'black' }}>Email *</label>
              <input type="email" id="email" name="email" placeholder="your@email.com" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="subject" style={{ color: 'black' }}>Subject *</label>
              <input type="text" id="subject" name="subject" placeholder="Subject" style={inputStyle} required />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="message" style={{ color: 'black' }}>Your Message</label>
              <textarea id="message" name="message" placeholder="Write Your Message" style={textareaStyle} />
            </div>
            <button type="submit" style={buttonStyle}>Send Your Message</button>
          </form>
        </div>

        {/* Contact Info Style */}
        <div style={{ width: '45%' }}>
          <h2 style={{ borderBottom: '1px solid #333', paddingBottom: '10px', color: 'black' }}>CONTACT INFO</h2>
          
          <p><br/>Address:  DC-7260, BHARAT  DIAMOND  BOURSE,BANDRA KURLA COMPLEX, BANDRA(E) MUMBAI 400051.</p>
         
          <p>Email your issues and suggestion for the following<br/>Email Addresses: india@royalraysbv.com</p>
          
          <div style={{ width: '75%', height: '320px', marginTop: '20px', marginLeft: '0', marginRight: '25%' }}>
            <iframe
              title="Our Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9860628326783!2d72.86135697497707!3d19.064350382137864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9db48bcc7d1%3A0x52c692071ad14131!2sBharat%20Diamond%20Bourse%20-%20Mumbai!5e0!3m2!1sen!2sin!4v1700820177087"
              width="100%" 
              height="100%" 
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '6px 0',
  display: 'inline-block',
  border: '1px solid #ccc',
  backgroundColor: '#f7f7f7', // Lighter background for input
  color: 'black', // Dark text for readability
  boxSizing: 'border-box'
};

const textareaStyle = {
  ...inputStyle,
  height: '150px',
  resize: 'vertical'
};

const buttonStyle = {
   backgroundColor: 'rgb(76 83 90)', 
  color: 'white', 
  padding: '14px 20px',
  margin: '8px 0',
  border: 'none',
  cursor: 'pointer',
  width: '100%',
  opacity: '0.9'
};

export default Contactus;
